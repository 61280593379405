<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import BarChartRecette from "./BarChartRecette";
import BarChartUser from "./BarChartUser";
import ChartLocation from "./ChartLocation.vue"
import ChartBus from "./ChartBus.vue"
import ChartVTC from "./ChartVTC.vue"
import SalesAnalytics from "./sales-analytics";
import { apiRequest } from "@/helpers/api-call";
import Multiselect from "vue-multiselect";
import router from "@/router/index.js";
/**
 * Dashboard component
 * 
 * 
 */

export default {
  components: {
    Layout,
    PageHeader,
    BarChartRecette,
    BarChartUser,
    ChartLocation,
    ChartBus,
    ChartVTC,
    SalesAnalytics,
    Multiselect,
  },
  data() {
    return {
      title: "TABLEAU DE BORD",
      items: [
        {
          text: "/",
        },
        {
          text: "TABLEAU DE BORD",
          active: true,
        },
      ],
      info: {
        totalUser: 0,
        trajetsAnnule: 0,
        trajetsEnCours: 0,
        trajetsAVenir: 0,
        trajetsEffectue: 0,
        totalCOEvite: 0,
        distance: 0,
        walletPositif: 0,
        soldePositif: 0,
        totalAlert: 0,
        totalReservation: 0,

      },
      locationInfo: {
        totalLocation: 0,
        totalTransaction: 0,
        totalVehiculeLocation: 0,
        coutTransaction: 0,
        nbr_autopartage: 0,
        cout_tansaction: 0,
        autopartageAnnule: 0,
        autopartageEffectue: 0,
        locationAnnulee: 0,
        totalDemandes: 0,
      },
      totalLocation: 0,
      totalCourse: 0,
      totalVTC: 0,
      year: [
        { name: "2021", value: 2021 },
        { name: "2022", value: 2022 },
      ],
      yearSelected: { name: "2022", value: 2022 },
      yearvalue: 2022,
      yearLocation: new Date().getFullYear(),
      yearVTC: new Date().getFullYear(),
      yearCourse: new Date().getFullYear(),
      years: [2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033, 2034, 2035, 2036, 2037, 2038, 2039, 2040, 2041, 2042, 2043, 2044, 2045, 2046, 2047, 2048, 2049, 2050],
      yearvalueU: 2022,
      nombre: 0,
      totalEvent: 0,
      totalEventAmount: 0,
      totalTicketSold: 0,
      totalEventCanceled: 0,
      totalRecette: 0,
      totalReservation: 0,
      taxiData: {
        co2: null,
        total_vtc_drivers: 0,
        total_distance: 0,
        total_courses: 0
      },

      today: new Date(),
      niveauUser: JSON.parse(localStorage.getItem("user")).niveau,
      busStat: null,
      urgenceStat:null,
    };
  },
  watch: {
    yearvalueU: {
      handler(val) {
        console.log("handler:", val);
        this.loadDataUser();
      },
    },
    yearvalue: {
      handler(val) {
        console.log("handler:", val);
        this.loadData();
      },
    },
  },
  async mounted() {
    if (this.niveauUser[0] == 15 && this.niveauUser.length == 1) {
      router.push(`/nsia/nsia_souscription`);
    }
    this.actualYear = this.today.getFullYear();
    this.yearSelected.name = this.actualYear
    this.yearSelected.value = this.actualYear
    this.yearvalue = this.actualYear
    this.yearvalueU = this.actualYear
    let user = JSON.parse(localStorage.getItem("user"));
    let niveau = user.niveau;
    console.log("niveau:", niveau);

    if (niveau.includes(0) || niveau.includes(17)) {
      //niveau[0] == 0
      this.init();
      this.loadData();
      this.loadDataUser();
      this.eventStat();
      await this.loadTaxiStatInfo();
      this.busCompanyStat()
      this.urgencesStat()
    }
    // else {
    //   if (niveau.includes(17)) {
    //     this.init();
    //     this.loadData();
    //     this.loadDataUser();
    //     this.eventStat();

    //   }
    // }
  },
  methods: {
    caLocation(totalLocation) {
      this.totalLocation = totalLocation;
    },
    caVTC(totalVTC) {
      this.totalVTC = totalVTC;
    },
    caCourse(totalCourse) {
      this.totalCourse = totalCourse;
    },

    async busCompanyStat() {

      const response = await apiRequest('GET', 'statistics/all-compagnies',)

      if (response && response.status == 200) {
        console.log("bus urgences", response.data)

        this.busStat = response.data;

      }

    },

    async urgencesStat() {

      const response = await apiRequest('GET', 'urgence-stats',)

      if (response && response.status == 200) {
        console.log("urgences globales", response.data)

        this.urgenceStat = response.data;

      }

    },
    async init() {
      //Chargement des info
      const data = await apiRequest("GET", "admin/dash-info", undefined, false);
      if (data && data.data) {
        console.log("data.data:", data.data);
        this.info.totalUser = data.data.totalUser;
        this.info.trajetsAnnule = data.data.trajetsAnnule;
        this.info.trajetsEnCours = data.data.trajetsEnCours;
        this.info.trajetsAVenir = data.data.trajetsAVenir;
        this.info.trajetsEffectue = data.data.trajetsEffectue;
        this.info.totalCOEvite = data.data.totalCOEvite;
        this.info.distance = Math.round(data.data.distance);
        this.info.walletPositif = data.data.walletPositif;
        this.info.soldePositif = data.data.soldePositif;
        this.info.totalReservation = data.data.nombrePlaceReserver


        console.log("total ", this.info.totalAlert);
      }

      //Location
      const datalocation = await apiRequest(
        "GET",
        "admin/rmo/dash-info",
        undefined,
        false
      );

      console.log("dataLocation", datalocation)
      //       {
      // 	“totalVehiculeLocation”: 17,
      // 	“totalVehiculeAutopartage”: 28,
      // 	“totalReservations”: 11,
      // 	“totalLocationsEffectuees”: 1,
      // 	“totalLocationsAnnulees”: 0,
      // 	“totalAutopartagesEffectues”: 12,
      // 	“totalAutopartagesAnnules”: 2,
      // 	“totalDemandes”: 77,
      // 	“cout_transaction_location”: 22000,
      // 	“cout_transaction_autopartage”: 189300,
      // 	“totalAlert”: 0
      // }
      if (datalocation && datalocation.data) {
        this.locationInfo.totalDemandes = datalocation.data.toltalReservationCovoiturage
        this.locationInfo.totalLocation = datalocation.data.totalLocationsEffectuees;
        this.locationInfo.totalTransaction = datalocation.data.totalTransaction;
        this.locationInfo.totalVehiculeLocation =
          datalocation.data.totalVehiculeLocation;
        this.locationInfo.coutTransaction = datalocation.data.cout_transaction_location;
        this.locationInfo.locationAnnulee = datalocation.data.totalLocationsAnnulees;
        this.locationInfo.nbr_autopartage = datalocation.data.totalVehiculeAutopartage;
        this.locationInfo.cout_tansaction = datalocation.data.cout_transaction_autopartage;
        this.locationInfo.autopartageAnnule =
          datalocation.data.totalAutopartagesAnnules;
        this.locationInfo.autopartageEffectue =
          datalocation.data.totalAutopartagesEffectues;
        this.info.totalAlert = datalocation.data.totalAlert;
      }
    },

    async eventStat() {
      //User by year
      const statEvent = await apiRequest("GET", "evenement/tikets-stat", undefined, false);



      if (statEvent && statEvent.data) {
        let statEventData = statEvent.data;

        //   console.log("lorem ipsum lorem ipsumlorem ipsumlorem ipsumlorem ipsumlorem ipsumlorem ipsumlorem ipsum ");
        // console.log(statEventData);



        if (statEventData) {
          this.totalEvent = statEventData.totalEvent;
          this.totalEventAmount = statEventData.montantTotalVendu;
          this.totalTicketSold = statEventData.totalTicketVendu;
          this.totalEventCanceled = statEventData.totalEventCanceled;

        }



      }
    },


    async loadDataUser() {
      //User by year
      const stat = await apiRequest("GET", "admin/stat-user", undefined, false);

      if (stat && stat.data) {
        let dataStat = stat.data;

        dataStat.annees.forEach((element) => {
          if (element.annee == this.yearvalueU) {
            this.nombre = element.total;
          }
        });
      }
    },
    async loadData() {
      this.totalRecette = 0;
      const gains = await apiRequest(
        "GET",
        "admin/gain/info",
        undefined,
        false
      );

      if (gains && gains.data) {
        this.totalRecette = 0;
        let dataRecette = gains.data.gains;
        dataRecette.forEach((element) => {
          if (this.yearvalue == element.annee) {
            this.totalRecette += element.recette;

            console.log("load data this.totalRecette", this.totalRecette)
          }
        });
        //this.totalRecette = gains.data.totalRecette;
      }
    },

    async loadTaxiStatInfo() {
      const response = await apiRequest('GET', 'rtaxi-statistics')

      if (response && response.status == 200) {
        console.log("taxi data", response.data)
        this.taxiData.co2 = parseFloat(response.data.co2)
        console.log("taxi data 2", this.taxiData.co2)
        this.taxiData.total_vtc_drivers = response.data.total_vtc_drivers
        this.taxiData.total_courses = response.data.total_courses
        this.taxiData.total_distance = response.data.total_distance

      }

    },



    nFormatter(num, digits) {
      const lookup = [
        { value: 1, symbol: "" },
        { value: 1e3, symbol: "k" },
        { value: 1e6, symbol: "M" },
        { value: 1e9, symbol: "G" },
        { value: 1e12, symbol: "T" },
        { value: 1e15, symbol: "P" },
        { value: 1e18, symbol: "E" }
      ];
      const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
      var item = lookup.slice().reverse().find(function (item) {
        return num >= item.value;
      });
      return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
    },


    goToUser() {
      router.push(`user/users_list`);
    },

    goToEvent() {
      router.push(`event/evenement`);
    },
    goToBus() {
      router.push(`agence/compagnie`);
    },
    goToVTC() {
      router.push(`taxi/user-list`);
    },
    goToVTCcourse() {
      router.push(`taxi/course`);
    },
    goToSoldTicket() {
      router.push(`event/pass/buy`);
    },


    goToAvenir() {
      router.push(`trajets/avenir`);
    },

    goToEncour() {
      router.push(`trajets/encours`);
    },


    goToEnDone() {
      router.push(`trajets/effectues`);
    },
    goToEnAnnule() {
      router.push(`trajets/annules`);
    },

    goToWCharge() {
      router.push(`wallet/solde`);
    },

    goToW() {
      router.push(`wallet/transactions`);
    },

    goToVAgence() {
      router.push(`agence/agencelist`);
    },

    goToLocation() {
      router.push(`agence/historique_paiement`);
    },

    goToReserve() {
      router.push(`agence/demande_reservation`);
    },

    goToLocationAnnule() {
      router.push(`trajets/encours`);
    },


    goToDemandeReservation() {
      router.push(`trajets/reservation`);
    },

    goToAlert() {


      router.push(`trajets/alertes`);
    },


    goToVehiculeENAutopartage() {
      router.push(`agence/user_list`);
    },
    goToVehiculeAutopartageDone() {
      router.push(`/trajets/encours`);
    },

    goToReserveAutopartage() {
      router.push(`/trajets/encours`);
    },

    goToReserveAutopartageAnnuke() {
      router.push(`/trajets/encours`);
    },

    goToReservation() {
      router.push(`/transactions/historique-transactions`);
    }





  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row myRowFlex">

      <div class="col-md-8 myColLeft">

        <div class="cardBox">
          <div class="carde trajet">
            <div @click="goToUser()">
              <div class="numbers"> {{ nFormatter(this.info.totalUser, '1') }}</div>
              <div class="cardName">Nombre d'utilisateurs</div>
            </div>
            <div class="iconBx text-success">
              <ion-icon name="people-circle"></ion-icon>
            </div>
          </div>
          <div class="carde trajet">
            <div @click="goToAvenir()">
              <div class="numbers"> {{ nFormatter(this.info.trajetsAVenir, '1') }} </div>
              <div class="cardName">Trajets à venir</div>
            </div>
            <div class="iconBx text-primary">
              <ion-icon name="timer"></ion-icon>
            </div>
          </div>

          <div class="carde trajet">
            <div @click="goToEncour()">
              <div class="numbers">{{ nFormatter(this.info.trajetsEnCours, '1') }}</div>
              <div class="cardName">Trajets en cours</div>
            </div>
            <div class="iconBx text-warning">
              <ion-icon name="sync-circle"></ion-icon>
            </div>
          </div>
          <div class="carde trajet">
            <div @click="goToAlert()">
              <div class="numbers"> {{ nFormatter(this.info.totalAlert, '1') }} </div>
              <div class="cardName">Alertes utilisateurs</div>
            </div>
            <div class="iconBx text-danger">
              <ion-icon name="warning"></ion-icon>

            </div>
          </div>
          <div class="carde trajet">
            <div @click="goToEnDone()">
              <div class="numbers">{{ nFormatter(this.info.trajetsEffectue, '1') }}</div>
              <div class="cardName">Trajets effectués</div>
            </div>
            <div class="iconBx text-success">
              <ion-icon name="checkmark-done-circle"></ion-icon>
            </div>
          </div>

          <div class="carde trajet">
            <div @click="goToReservation()">
              <div class="numbers">{{ nFormatter(this.info.totalReservation, '1') }}</div>
              <div class="cardName">Total places réservées</div>
            </div>
            <div class="iconBx text-primary">
              <ion-icon name="checkmark-done-circle"></ion-icon>
            </div>
          </div>

          <div class="carde trajet">
            <div @click="goToDemandeReservation()">
              <div class="numbers">{{ nFormatter(this.locationInfo.totalDemandes, '1') }}</div>
              <div class="cardName">Demande de réservation</div>
            </div>
            <div class="iconBx text-warning">
              <ion-icon name="timer"></ion-icon>
            </div>
          </div>

          <div class="carde trajet">
            <div>
              <div class="numbers">{{ nFormatter(this.info.totalCOEvite, '1') }}</div>
              <div class="cardName">Quantité C0<sub>2</sub> évitée en Kg</div>
            </div>
            <div class="iconBx text-danger">
              <ion-icon name="logo-no-smoking"></ion-icon>
            </div>
          </div>
          <div class="carde trajet">
            <div @click="goToW()">
              <div class="numbers">{{ nFormatter(this.info.walletPositif, '1') }}</div>
              <div class="cardName">Wallets chargés</div>
            </div>
            <div class="iconBx text-success">
              <ion-icon name="wallet"></ion-icon>
            </div>
          </div>
          <div class="carde trajet">
            <div @click="goToWCharge()">
              <div class="numbers">{{ nFormatter(this.info.soldePositif, '1') }}</div>
              <div class="cardName">Soldes chargés</div>
            </div>
            <div class="iconBx text-primary">
              <ion-icon name="cash"></ion-icon>
            </div>
          </div>


          <div class="carde trajet">
            <div>
              <div class="numbers">{{ nFormatter(this.info.distance, '1') }}</div>
              <div class="cardName">Nombre Total de km</div>
            </div>
            <div class="iconBx text-warning">
              <ion-icon name="analytics"></ion-icon>
            </div>
          </div>

          <div class="carde trajet">
            <div @click="goToEnAnnule()">
              <div class="numbers">{{ nFormatter(this.info.trajetsAnnule, '1') }}</div>
              <div class="cardName">Trajets annulés</div>
            </div>
            <div class="iconBx text-danger">
              <ion-icon name="close-circle"></ion-icon>
            </div>
          </div>
        </div>
        <!-- <div class="cardBox">
          <div class="carde location">
            <div  @click="goToVAgence()">
              <div class="numbers">
                {{ nFormatter(locationInfo.totalVehiculeLocation, '1') }}
              </div>
              <div class="cardName">Véhicules d'agence</div>
            </div>
            <div class="iconBx">
              <ion-icon name="car-outline"></ion-icon>
            </div>
          </div>
          <div class="carde location">
            <div  @click="goToLocation()">
              <div class="numbers">{{ nFormatter(locationInfo.totalLocation, '1') }}</div>
              <div class="cardName">Total locations</div>
            </div>
            <div class="iconBx">
              <ion-icon name="logo-apple-ar"></ion-icon>
            </div>
          </div>
          <div class="carde location">
            <div  @click="goToReserve()">
              <div class="numbers">{{nFormatter( locationInfo.coutTransaction, '1')}}</div>
              <div class="cardName">Réservations agences</div>
            </div>
            <div class="iconBx">
              <ion-icon name="move-outline"></ion-icon>
            </div>
          </div>
          <div class="carde location">
            <div  @click="goToLocationAnnule()">
              <div class="numbers">{{nFormatter( locationInfo.locationAnnulee, '1') }}</div>
              <div class="cardName"> annulées</div>
            </div>
            <div class="iconBx">
              <ion-icon name="close-circle-outline"></ion-icon>
            </div>
          </div>
        </div> -->

        <div class="cardBox">
          <div class="carde vtc-border">
            <div @click="goToVTC()">
              <div class="event">{{ nFormatter(this.taxiData.total_vtc_drivers, '1') }}</div>
              <div class="cardName">Conducteurs VTC</div>
            </div>
            <div class="iconBx text-success">
              <ion-icon name="people-circle"></ion-icon>
            </div>
          </div>

          <div class="carde vtc-border">
            <div @click="goToVTCcourse()">
              <div class="event">{{ nFormatter(this.taxiData.total_courses, '1') }}</div>
              <div class="cardName">Courses</div>
            </div>
            <div class="iconBx text-primary">
              <ion-icon name="layers"></ion-icon>
            </div>
          </div>

          <div class="carde vtc-border">
            <div @click="goToVTCcourse()">
              <div class="event">{{ nFormatter(this.taxiData.total_distance, '1') }}</div>
              <div class="cardName">Nombre de Km VTC </div>
            </div>
            <div class="iconBx text-warning">
              <ion-icon name="navigate-circle"></ion-icon>
            </div>
          </div>
          <div class="carde vtc-border">
            <div @click="goToVTCcourse()">
              <div class="event">{{ nFormatter(this.taxiData.co2, '3') }}</div>
              <div class="cardName">C02 évités - VTC/Kg</div>
            </div>
            <div class="iconBx text-danger">
              <ion-icon name="logo-no-smoking"></ion-icon>
            </div>
          </div>
          <div class="carde autopartage">
            <div @click="goToVehiculeAutopartageDone()">
              <div class="numbers">{{ nFormatter(locationInfo.autopartageEffectue, '1') }}</div>
              <div class="cardName">Autopartages effectués</div>
            </div>
            <div class="iconBx text-success">
              <ion-icon name="checkmark-done-circle"></ion-icon>
            </div>
          </div>
          <div class="carde autopartage">
            <div @click="goToVehiculeENAutopartage()">
              <div class="numbers">{{ nFormatter(locationInfo.nbr_autopartage, '1') }}</div>
              <div class="cardName">Véhicules autopartage</div>
            </div>
            <div class="iconBx text-primary">
              <ion-icon name="car"></ion-icon>
            </div>
          </div>


          <div class="carde autopartage">
            <div @click="goToReserveAutopartage()">
              <div class="numbers">{{ nFormatter(locationInfo.cout_tansaction, '1') }}</div>
              <div class="cardName">Réservations autopartage</div>
            </div>
            <div class="iconBx text-warning">
              <ion-icon name="bookmarks"></ion-icon>
            </div>
          </div>


          <div class="carde autopartage">
            <div @click="goToReserveAutopartageAnnuke()">
              <div class="numbers">{{ nFormatter(locationInfo.autopartageAnnule, '1') }}</div>
              <div class="cardName">Autopartages annulés</div>
            </div>
            <div class="iconBx text-danger">
              <ion-icon name="close-circle"></ion-icon>
            </div>
          </div>

          <div class="carde event">
            <div @click="goToEvent()">
              <div class="event">{{ nFormatter(this.totalEvent, '1') }}</div>
              <div class="cardName">Total des events</div>
            </div>
            <div class="iconBx text-success">
              <ion-icon name="megaphone"></ion-icon>
            </div>
          </div>
          <div class="carde event">
            <div @click="goToSoldTicket()">
              <div class="event">{{ nFormatter(this.totalTicketSold, '1') }}</div>
              <div class="cardName">Pass achetés</div>
            </div>
            <div class="iconBx text-primary">
              <ion-icon name="card"></ion-icon>
            </div>
          </div>
          <div class="carde event">
            <div @click="goToEvent()">
              <div class="event">{{ nFormatter(this.totalEventAmount, '1') }}</div>
              <div class="cardName">Coût des achats</div>
            </div>
            <div class="iconBx text-warning">
              <ion-icon name="cash"></ion-icon>
            </div>
          </div>
          <div class="carde event">
            <div @click="goToEvent()">
              <div class="event">{{ nFormatter(this.totalEventCanceled, '1') }}</div>
              <div class="cardName">Évènements annulés </div>
            </div>
            <div class="iconBx text-danger">
              <ion-icon name="ban"></ion-icon>
            </div>
          </div>


          <!-- Ticket de bus stat -->

          <div class="carde bus">
            <div @click="goToBus()">
              <div class="event">{{ (this.busStat != null && this.busStat.total_ligne_bus ) ?  this.busStat.total_ligne_bus : "0" }}</div>
              <div class="cardName">Lignes</div>
            </div>
            <div class="iconBx text-success">
              <ion-icon name="subway"></ion-icon>
            </div>
          </div>
          <div class="carde bus">
            <div @click="goToBus()">
              <div class="event">{{  (this.busStat != null && this.busStat.total_societe  ) ? this.busStat.total_societe : "0" }}</div>
              <div class="cardName">Compagnies de Bus</div>
            </div>
            <div class="iconBx text-primary">
              <ion-icon name="business"></ion-icon>
            </div>
          </div>
          <div class="carde bus">
            <div @click="goToBus()">
              <div class="event">{{  (this.busStat != null && this.busStat.total_ticket  )?  this.busStat.total_ticket :  "0" }}</div>
              <div class="cardName">Tickets de Bus</div>
            </div>
            <div class="iconBx text-warning">
              <ion-icon name="ticket"></ion-icon>
            </div>
          </div>
          <div class="carde bus">
            <div @click="goToBus()">
              <div class="event">{{ (this.busStat && this.busStat.total_ticket_annule != null ) ?  this.busStat.total_ticket_annule : "0" }}</div>
              <div class="cardName">Tickets de Bus annulés </div>
            </div>
            <div class="iconBx text-danger">
              <ion-icon name="close-circle"></ion-icon>
            </div>
          </div>

          <!-- Les urgences  -->

           <!-- Ticket de bus stat -->

           <div class="carde alert">
            <div >
              <div class="event">{{( this.urgenceStat != null && this.urgenceStat.total_urgence )?  this.urgenceStat.total_urgence : "0" }}</div>
              <div class="cardName">Urgences globales</div>
            </div>
            <div class="iconBx text-success">
              <ion-icon name="alert-circle"></ion-icon>
            </div>
          </div>
          <div class="carde alert">
            <div >
              <div class="event">{{  ( this.urgenceStat != null && this.urgenceStat.urgences_course )? this.urgenceStat.urgences_course : "0" }}</div>
              <div class="cardName">Urgences VTC</div>
              
            </div>
            <div class="iconBx text-primary">
              <ion-icon name="alert-circle"></ion-icon> <ion-icon name="car-outline"></ion-icon>
            </div>
          </div>
          <div class="carde alert">
            <div >
              <div class="event">{{ ( this.urgenceStat != null && this.urgenceStat.urgences_couvoiturage )? this.urgenceStat.urgences_couvoiturage : "0" }}</div>
              <div class="cardName">Urgences covoiturage</div>
            </div>
            <div class="iconBx text-warning">
              <ion-icon name="alert-circle"></ion-icon><ion-icon name="people-circle-outline"></ion-icon>
            </div>
          </div>
          <!-- <div class="carde bus">
            <div @click="goToEvent()">
              <div class="event">{{ this.busStat.total_ticket_annule || "0" }}</div>
              <div class="cardName">Tickets de Bus annulés </div>
            </div>
            <div class="iconBx text-danger">
              <ion-icon name="close-circle"></ion-icon>
            </div>
          </div> -->





        </div>
        <hr class="hr-13">
        <div class="row mt-4"
          v-if="!(niveauUser[0] == 15 && niveauUser.length == 1) && (niveauUser[0] == 0 || niveauUser[0] == 17)">
          <div class="col-md-12 col-sm-12">
            <div class="p-1">
              <div class="row mb-4 d-flex justify-content-center">
                <div class="mx-auto d-flex justify-content-between">
                  <div>
                    <multiselect style="text-align: center" v-model="yearvalueU" :options="years"
                      :close-on-select="true" :show-labels="false" :allow-empty="false"></multiselect>
                  </div>
                  &nbsp;&nbsp;
                  <div class="mt-2">Total : {{ nombre }}</div>
                </div>
              </div>
              <!-- Bar Chart -->
              <b-card>
                <BarChartUser :height="300" :annee="yearvalueU" />
              </b-card>

            </div>
          </div>
        </div>
        <div class="row mt-4"
          v-if="!(niveauUser[0] == 15 && niveauUser.length == 1) && (niveauUser[0] == 0 || niveauUser[0] == 17)">
          <div class="col-md-12 col-sm-12">
            <div class="p-1">
              <div class="row mb-4 d-flex justify-content-center">
                <div class="mx-auto d-flex justify-content-between">
                  <div class="">
                    <multiselect style="text-align: center" v-model="yearvalue" :options="years" :close-on-select="true"
                      :show-labels="false" :allow-empty="false"></multiselect>
                  </div>
                  &nbsp;&nbsp;
                  <div class="mt-2">Total : {{ totalRecette }}</div>
                </div>
              </div>
              <!-- Bar Chart -->
              <b-card>
                <BarChartRecette :height="300" :annee="yearvalue" />
              </b-card>

              <div class="row mb-4 d-flex justify-content-center">
                <div class="mx-auto d-flex justify-content-between">
                  <div class="">
                    <multiselect style="text-align: center" v-model="yearLocation" :options="years"
                      :close-on-select="true" :show-labels="false" :allow-empty="false"></multiselect>
                  </div>
                  &nbsp;&nbsp;

                </div>
              </div>
              <b-card>
                <div class="mt-2 mb-4 d-flex justify-content-center">Total : {{ totalLocation }}</div>
                <ChartLocation @totalCALocation="caLocation" :height="300" :annee="yearLocation" />
              </b-card>

              <div class="row mb-4 d-flex justify-content-center">
                <div class="mx-auto d-flex justify-content-between">
                  <div class="">
                    <multiselect style="text-align: center" v-model="yearVTC" :options="years" :close-on-select="true"
                      :show-labels="false" :allow-empty="false"></multiselect>
                  </div>
                  &nbsp;&nbsp;

                </div>
              </div>
              <b-card>
                <div class="mt-2 mb-4 d-flex justify-content-center">Total : {{ totalVTC }}</div>
                <ChartVTC @totalCAVTC="caVTC" :height="300" :annee="yearVTC" />
              </b-card>
              <div class="row mb-4 d-flex justify-content-center">
                <div class="mx-auto d-flex justify-content-between">
                  <div class="">
                    <multiselect style="text-align: center" v-model="yearCourse" :options="years"
                      :close-on-select="true" :show-labels="false" :allow-empty="false"></multiselect>
                  </div>
                  &nbsp;&nbsp;

                </div>
              </div>
              <b-card>
                <div class="mt-2 mb-4 d-flex justify-content-center">Total : {{ totalCourse }}</div>
                <ChartBus @totalCACourse="caCourse" :height="300" :annee="yearCourse" />
              </b-card>
            </div>
          </div>
        </div>

      </div>
      <div class="col-md-4 myColRigth" v-if="!(niveauUser[0] == 15 && niveauUser.length == 1)">

        <SalesAnalytics />

      </div>

    </div>
  </Layout>


</template>

<style>
/** column usage */

.alert{
  border: 2px solid rgb(72, 160, 180);

}

.vtc-border {
  border: 2px solid orange;
  border-color: orange,

}

.col-left {

  flex-basis: 0.6;

}

.col-right {
  flex-basis: 0.3;

}

.infotitle {
  white-space: wrap;
  color: #222;
}

.myCard {
  background: #ffffff;
  padding: 0.385rem;
  /*box-shadow: 2px 2px 3px #2c3333;*/
  border: 0 !important;
  height: 10rem;
  transition: ease 0.3s;
}

.myCard:hover {
  background: transparent;
  padding: 0.385rem;

  outline: none;

  border: 0 !important;
  height: 10rem;
  backdrop-filter: blur(15px);
  /* box-shadow: -10px -10px 15px rgba(255, 255, 255, 0.5),
    10px 10px 15px rgba(70, 70, 70, 0.12),
    inset -10px -10px 15px rgba(255, 255, 255, 0.5),
    inset 10px 10px 15px rgba(0, 0, 0, 0.12); */
}

.alignBottom {
  position: absolute;
  bottom: 1.2rem;
  right: 0;
  padding-right: 1.5rem;
}

.cardBox {
  position: relative;
  padding: 20px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  grid-gap: 20px;
}

.cardBox .carde {
  display: flex;

  justify-content: space-between;
  position: relative;
  background: #fff;
  padding: 30px;
  /* box-shadow: 0 7px 25px rgba(0, 0, 0, 0.08); */
  border-radius: 20px;
  cursor: pointer;
}

.cardBox .carde.trajet {
  border: 2px solid green;
}

.cardBox .carde.location {
  border: 2px solid rgb(246, 51, 51);
  height: 155px;
}

.cardBox .carde.autopartage {
  border: 2px solid #3ea6ff;
  height: 155px;
}

.cardBox .carde.event {
  border: 2px solid rgb(245, 108, 108);
  height: 155px;
}

.cardBox .carde.bus {
  border: 2px solid rgb(147, 55, 238);
  height: 155px;
}

.cardBox .carde .numbers {
  position: relative;
  font-weight: 500;
  font-size: 2rem;
  color: #252b3b;
}

.cardBox .carde .event {
  position: relative;
  font-weight: 500;
  font-size: 2rem;
  color: #000000;
}

.cardBox .carde .numbers .cardName {
  color: #999;
  font-size: 1.1em;
  margin-top: 5px;
}

.cardBox .carde .event .cardName {
  color: #999;
  font-size: 1.1em;
  margin-top: 5px;
}

.cardBox .carde .iconBx {
  font-size: 3em;
  color: #999;
}

body {
  background: #fff;
}

.cardBox .carde:hover {
  background: #000000;
}

.cardBox .carde:hover .event,
.cardBox .carde:hover .numbers,
.cardBox .carde:hover .cardName,
.cardBox .carde:hover .iconBx {
  color: #ffffff;
}


.boxNotif {
  box-sizing: border-box;
}

.carde.trajet {
  height: 155px;
}

hr.hr-13 {
  height: 10px;
  border: 0;
  box-shadow: 0 10px 10px -10px #8c8c8c inset;
}

@media only screen and (max-width: 1460px) {
  .myRowFlex {
    display: flex;
    justify-content: center;
    flex-direction: column;


  }

  .myColLeft {
    flex: 100%;
    background: #f7f7f7;
    max-width: 100% !important;

  }


  .myColRigth {
    flex: 100%;
    max-width: 100% !important;



  }

}


/* @media only screen and (max-width: 1377px) {
  .col-left {
  flex-basis: 100%;
  background: #f9f9f9;
}

.col-right {
  flex-basis: 100%!important;


  
}

} */
</style>
